/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 3; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */
form .form-group label {
  font-weight: bold;
}

form .btn-primary {
  float: right;
}

#main #panel-wrapper #sidebar-links li {
  list-style: none;
}
#main #panel-wrapper #sidebar-links li a {
  text-decoration: none;
  background-color: transparent;
  border-bottom: none;
  color: inherit;
}
